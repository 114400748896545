/* Mobile-specific styles */
@media only screen and (max-width: 480px) {
  .contact-form-container {
      padding: 1rem;
  }

  .contact-form-container h1 {
      font-size: 15px !important;
  }

  .subtext{
    font-size: 10px !important;
  }

  
  .form-container {
      padding: 16px !important;
      overflow: hidden;
      height: fit-content !important;
  }

  .form-row{
    display: flex;
    flex-direction: column;
    gap: 0px !important;
  }
  

  .submit-button {
      width: 100%;
      margin: 10px 0px !important;
      place-self: center !important;
      font-size: 10px !important;
      

  }

  .form-group label {
    font-size: 10px !important;
  }

  .form-group input{
    font-size: 10px !important;
  }

  .contact-form-submit-button{
    text-align-last: center;
  }

}
/* General form container styling */
.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    color: #fff;
    background-color: #000;
  }
  
  .contact-form-container h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .contact-form-container .highlight {
    color: lightskyblue; /* Matches the blue color in "Elevate" */
  }
  
  .contact-form-container .subtext {
    font-size: 1rem;
    color: #b0b0b0;
    text-align: center;
    max-width: 400px;
    margin-bottom: 1.5rem;
  }
  
  .form-container {
    background-color: #2d2d2d;
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    height: 500px;
  }
  
  .form-row {
    display: flex;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .form-group label {
    font-size: 0.9rem;
    color: #b0b0b0;
    margin-bottom: 0.5rem;
    margin-top: 25px;
  }
  
  .form-group input {
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #555;
    border-radius: 4px;
    background-color: #1c1c1c;
    color: #fff;
  }
  
  .submit-button {
    width: 30%;
    padding: 0.8rem;
    font-size: 1rem;
    color: #fff;
    background-color: #00bfff; /* Matches the Submit button color */
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 70px;
    margin-left: 350px;
  }
/*   
  .submit-button:hover {
    background-color: #008fbf;
  } */

  .submit-button:hover {
    box-shadow: 0 0 15px cyan, 0 0 30px cyan;
    transform: scale(1.05);
  }
  
  /* Footer and additional spacing if needed */
  .contact-form-container .footer {
    margin-top: 2rem;
    color: #b0b0b0;
    text-align: center;
  }