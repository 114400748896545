body {
  font-family: Arial, sans-serif;
  color: #fff;
  background-color: #000;
  overflow-x: hidden;
}


header.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #000;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 50px;
  box-shadow: 0px 0.1px 0px #ffffff;
  
  border-bottom-width: 10px;
}

@media only screen and (max-width: 480px) {

  .ipapologo{
    max-width: fit-content !important;
    height: 20% !important;
  }
  /* styles for devices with a maximum screen width of 480px */
  header.navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    background: #000000;
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 20px;
    box-shadow: 0px 0.1px 0px #ffffff;
    
    border-bottom-width: 10px;
    align-items: center;
  }
  .logo img {
    width: 100px;
    height: 35px !important;
    justify-content: center;
  }
  
  
  .main-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  .join-waitlist-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222; /* Dark background */
    color: #fff;
    border-radius: 15px;
    padding: 0px 0px 0px 20px;
    width: 80%;
    height: 20%;
    margin: 30px auto;
    overflow-x: hidden;
  }
  .Big-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    color: #0000;
    padding: 40px;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
  }
 

  
}



.logo {
  font-size: 24px;
  color: #6CF75A;
  
}

.logo img {
  width: 100px;
  height: auto;
  
}

.contactbutton{
  align-content: center;
}


.cta-button, .cta-2-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  transition: box-shadow 0.6s ease, transform 0.6s ease;
}


.cta-button {
  background-color: cyan;
  color: black;
  justify-content: center;
}

.cta-button:hover, .cta-button.hovered {
  box-shadow: 0 0 15px cyan, 0 0 30px cyan;
  transform: scale(1.05);
}

@media only screen and (max-width: 480px) {
  .hero{
    padding: 20px 10px !important;
  }
  .hero h1 {
    font-size: 20px !important;
    font-weight: 100;
    margin: 10px 0px;
    text-align: center;
  }

  .hero p {
    font-size: 10px !important;
    margin-bottom: 10px !important

  }

  .client-list-container{
    display: flex !important;
    width: 200% !important;
  }
  
  .client-list{
    height: 20px !important;
    filter: grayscale(100%);
    
  }
  
  .client-list-wrapper{
    
    margin: 20px 0px !important;
   
    height: fit-content !important;
  }

  .cta-button, .cta-2-button {
    padding: 5px 10px !important;
    font-size: 10px !important;
  }
  .cta-2-button {
    margin-top: 10px !important;
    border-radius: 5px !important;
  }
}

.hero {
  text-align: center;
  height: 100%;
  align-content: center;
  padding: 0px;
}


.hero h1 {
  font-weight: 100;
  font-size: 72px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.hero span {
  color: lightskyblue;
}

.hero p{
  font-size: 18px;
  font-weight: 100;
  margin-top: 5%;
}

.hero.white_In{
  color: white;
}

.Color_Span {
  color: lightskyblue;
}

.cta-2-button {
  background-color: rgb(36, 37, 36);
  color: white;
  border: 1px solid #707070;
  border-radius: 16px;
  padding: 16px 32px;
}

.cta-2-button:hover, .cta-2-button.hovered {
  box-shadow: 0 0 15px rgba(130, 240, 246, 0.6), 0 0 30px rgba(130, 240, 246, 0.6);
  transform: scale(1.05);
}

.client-list{
  filter: grayscale(100%);
}
.client-list-wrapper {
  width: 80vw;
  height: 100%; /* Adjust this to the size of your image */
  overflow: hidden;
  position: relative;
  
  place-self: center;
}



.client-list-container {
  display: flex;
  width: 400%;

  padding-bottom: 10px;

  
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(10%); /* Start from 30% from the right */
  }
  100% {
    transform: translateX(-10%); /* End at the left side of the screen */
  }
}

.paragraph{
   color: #545050;
}


{/*.main-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  max-width: 90%;
}

.large-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: scale(0.85);
  transform-origin: top center;
}

.small-card-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.small-card {
  position: relative;
  width: 260px;
  height: 320px;
  background-color: #2f2f2f;
  border-radius: 10px;
}

.blank-card {
  visibility: hidden;
}

.visible-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}*/}



@media only screen and (max-width: 480px) {
  .main-card-container{
    gap: 30px;
    padding: 10px  !important;
  }

  .large-card{
    display: flex !important;
    
  }

  .small-card-row {
    display:flex!important;
    flex-direction: column;
    column-gap: 30px !important;
  }
  

  .small-card{
    display: flex !important;
    flex-direction: row !important;
    height: 50px !important;
    align-items: center !important;
    min-width:fit-content !important;
    justify-content: space-between !important;
    padding: 10px !important;
    width: 80vw !important;
  }

  .card-gif{
    height: 60px !important;
    width: 60px !important;
    margin: 0px !important;
  }
  .card-text{
    text-align: baseline !important;
    margin: 0px !important;
    width: 100% !important;
  }

  .card-text h2{
    font-size: 16px !important;
    margin: 0px !important;
  }
   .card-text h3{
    font-size: 10px !important;
    margin: 0px !important;

   }

   .card-text p{
    font-size: 10px !important;
    text-align: center;
   }

   .first-card{
    text-align: right;
   }
}

.main-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
}

.large-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.small-card-row {
  display: flex;
  gap: 10px;
  
}

.small-card {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 298px;
    height: 374px;
    background-color: #242424;
    border-radius: 24px;
    color: #fff;
    text-align: center;
    padding: 30px;
}

.blank-card {
  background-color: transparent;
}

.card-text h2 {
  font-size: 35px;
  margin: 0;
  font-weight: 500;
}

.blank-card p{
  font-size: 22px;
  text-align: left;
}

.card-text h3 {
  font-size: 22px;
  font-weight:500;
  
  margin: 5px 0 0;
}

.card-text span{
  color:#02b3bd
}
.card-text p{
  margin: 0%;
  font-size: 28px;
  margin-left: 10px;
  text-align: left;
}

.card-gif {
  align-self: center;
  width: 84%;
  height: 71%;
  margin-top: 10px;
}

/* Specific text alignments for small-card content */
.visible-card .card-text {
  margin-top: 10px;
  text-align: left;
}



.AssetManagement{


}

@media only screen and (max-width: 480px) {
  .Big-container{
    display: flex !important;
    flex-direction: column;
    min-height: fit-content !important;
    padding: 30px 0px !important;
  }

  .left-side{
    max-width: 100% !important;
  }

  .title{
    margin: 10px 0px !important;
    font-size: 15px !important;
    text-align: left;
  }

  .subtitle{
    font-size: 12px !important;
    text-align: left !important;
  }

  .description{
    width: 100% !important;
    font-size: 10px !important;
    margin: 0px !important;
    text-align: baseline !important;
  }

  .threeframegroup{
    height: 100% !important;
    width: 100% !important;
    padding-top: 10px !important;
  }

  .button-group{
    padding: 10px 0px !important;
    justify-content: center;
  }
  
  .threebutton{
    padding: 5px 10px !important;
    font-size: 10px !important;
    align-self: center !important;
  }

}

.left-side {
  max-width: 60%;
}

.right-side {
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}


 

.Big-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
  color: #0000;
  padding: 10% 0%;
  min-height: fit-content;
  font-family: Arial, sans-serif;
  place-self: center;
}


/* Title and subtitle styling */
.title {
  width: 100%;
  font-size: 57px;
  font-weight:500;
  margin-bottom: 20px;
  color: #ffffff;
}

.subtitle {
  font-size: 22px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 15px;
}

/* Paragraph styling */
.description {
  width: 67%;

  font-size: 22px;
  line-height: 1.6;
  color: #707070;
  margin-bottom: 30px;
  text-align: left;
}

.button-group {
  display: flex;
  gap: 10px;
}

.threebutton {
  padding: 10px 20px;
  background-color: #333333;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;
}

.threebutton:hover {
  background-color: #555555;
}

.threebutton.active {
  background-color: #171818;
  color: #ffffff;
  box-shadow: 0px 4px 0px #009ab5;
  transform: scale(1.05);
  border-bottom-width: 10px;
}




/* Right-side image frame styling */
/*.image-frame {
  
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
}*/

.threeframegroup{
  width: 120%;
  float: right;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.startbuild {
  text-align: center;
  padding: 100px px;
}

@media only screen and (max-width: 480px) {
  .startbuild{
    padding: 10px !important;

  }
  
  .startbuild h1{
    margin: 0px !important;
    font-size: 15px !important;
    text-align:center;
    margin: 10px 0px !important;

  }

  .startbuild p{
    font-size: 10px !important;
  }

  .video-card{
    height: auto !important;
  }

  .outer-video-card{
    object-fit: contain !important;
    height: fit-content !important;
    width: 100% !important;
    overflow-x: hidden !important;
    padding: 0px !important;
    
  }
  
  .card-slider{
    padding-bottom: 40px !important;
  }
  .card-active{
    width: 100px !important;
  }
  
  .nav-button{
    transform: translateY(20) !important ;

  }

  .card-video{
    width: 100vw;
    object-fit: contain;
  }

}

.startbuild h1 {
  font-size: 48px;
  font-weight: 300;
  line-height: 1.2;
}

.startbuild p{
  color: #707070;
  font-size: 22px;
}

.startbuild span {
  color: lightskyblue;
}

.startbuild.white_In{
  color: white;
}
.Color_Span {
  color: lightskyblue;
}


.outer-video-card {
  width: 100%; /* Slightly larger than the video card (adjust as needed) */
  height: 100%; /* Slightly larger than the video card (adjust as needed) */
  
  
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 100px;
}

.video-card {
  flex: 1 0 0px;
  width: 100%;
  height: 100%;
  
  
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensures any overflow is clipped to the border radius */
}

.card-video {
  width: 100%;
  height: 100%;
  object-fit:cover; /* Ensures the video scales to fill the container */
  
  
}

/*.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

 .testimonial-header {
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 20px;
  padding: 100px 20px;

} 


.hero-2 {
  text-align: left;
  padding: 60px 20px;
  margin-left: -750px;
}

.hero-2 h1 {
  font-size: 35px;
  line-height: 1.2;
}

.testimonial-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  position: relative;
  max-width: 100%;
}

.testimonial-card {
  width: 584px;
  height: 349px;
  flex: 0 0 auto;
  transition: transform 0.5s ease;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}


.test-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: #1d1d1d;
  padding: 0 50px;
  color: #ffffff;
}

.text-section {
  flex: 1;
  max-width: 300px;
}

.text-section h1 {
  font-size: 2rem;
  margin: 0;
}

.text-section p {
  font-size: 1rem;
  color: #b5b5b5;
  margin-top: 10px;
}

.carousel {
  flex: 2;
  display: flex;
  align-items: center;
  position: relative;
  width: 600px;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-card {
  min-width: 300px;
  margin-right: 20px;
  padding: 20px;
  background-color: #333;
  border-radius: 10px;
  opacity: 0.5;
  transform: scale(0.9);
  transition: all 0.5s ease;
  box-sizing: border-box;
}

.carousel-card.active {
  opacity: 1;
  transform: scale(1);
}

.carousel-card.next {
  opacity: 0.7;
  transform: scale(0.95);
}

.carousel-controls {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
}

.carousel-button {
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
}

.carousel-button:hover {
  background-color: #555;
}


.carousel-card.inactive {
  display: none;
}*/
@media only screen and (max-width: 480px) {
.join-waitlist-card {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #000 !important; /* Dark background */
  color: #fff;
  border-radius: 15px;
  padding: 0px !important;
  width: 80%;
  height: 20%;
  margin: 30px auto;
  overflow-x: hidden;
}

.waitlist-info{
  margin: 0 !important;
  justify-content: center;
  justify-items: center;
}

.Upper_Heading{
  font-size: 15px !important;
  text-align: center;
  font-weight: 100;
  margin: 0px !important;
}

.waitlist-info h2{
  font-size: 15px !important;
  text-align: center;
}

.waitlist-info p{
  font-size: 10px !important;
  text-align: center;
  margin: 20px 0px !important;
}

.mockup-card{
  width:fit-content !important;
  height: fit-content !important;
  margin: 0px !important;
  padding: 0px !important;
}

.mockup-image{
  width: 100%;
  height: 100%;
  margin: 0px !important;
}

.card-slider{
  padding-top: 0px !important;
  width: 80vw !important;
}


.text-section{
  margin: 0px !important;
}

.join-waitlist-button{
  margin-top: 20px;
  margin-left: 0px !important;


}

}

/* JoinWaitlistCard.css */
.join-waitlist-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222; /* Dark background */
  color: #fff;
  border-radius: 15px;
  
  width: 80%;
  height: 20%;
  margin: 30px auto;
  place-self: center;
}

.waitlist-info {

  width: 100%;
 
  margin-left: 3%;
}

.waitlist-info h2 {
  
  font-size: 76px;
  font-weight: 100;
  margin: 0px;
}

.waitlist-info p {
  font-size: 22px;
  margin-bottom: 20px;
  max-width: 80%;
  color: #707070;
}

.join-waitlist-button {
  margin-left: 20px;

  width: fit-content;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  color: #222;
  border: none;
  cursor: pointer;
  padding: 0px 17px;

}

.join-waitlist-button:hover {
  background-color: cyan;
  box-shadow: 0 0 15px cyan, 0 0 30px cyan;
  transform: scale(1.05);
}


.mockup-card {
  padding-top: 55px;
  border-radius: 20px;
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mockup-image{
  margin-top: 32%;
  height: fit-content;
  width: 100%;
}

.Color_Span {
  color: lightskyblue;
}

.Upper_Heading {
  font-size: 28px;
  font-weight: 100;
  margin-top: 30px;
}
.arrow-img{
  
}


@media only screen and (max-width: 480px) {

  .text-section{
    padding: 0px !important;
  }

  .text-section h1{
    font-size: 15px !important;
    text-align: center;
  }

  .text-section p{
    font-size: 12px !important;
    text-align: center;
  }


  .testimonials-content{
  display:flex !important;
  flex-direction: column;
  }

  .card-slider{
    width: 80vw !important;
    
  }

  .video-card{
    padding-top: 10%;
  }

  .card h2{
    font-size: 11px !important;
  }

  .card h3{
    font-size: 10px !important;
  }

  .card p{
    font-size: 9px !important;
  }

  .nav-button.left{
    left: 25% !important;
  }

  .nav-button.right{
    right: 25% !important;
  }
  
  .card{
    margin: 10px 5px !important
  }

}

/* Container */
.testimonials {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 80vw;
  place-self: center;
}

.testimonials-content {
  display: flex;
  max-width: 80vw;
  width: 100%;
  align-items: center;
}

.text-section {
  flex: 1;
  padding-right: 2rem;
  margin-bottom: 50px;
}

.text-section h1 {
  font-size: 50px;
  margin-bottom: 0.5rem;
  font-weight: 300;
  margin: 0px;
}

.text-section p {
  font-size: 1.2rem;
  color: #666;
}

.card-slider {
  flex: 1;
  position: relative;
  overflow: hidden;
  height: fit-content;
  padding-bottom: 10%;
  padding-top: 5%;

  
  justify-content: center;

}

.cardbutton{
  padding: 10%;
  margin-top: 10px;
}


.cards-wrapper {
  display: flex;
  transition: transform 0.5s ease;
   
  
}

.card {
  
  min-width: fit-content;
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  justify-items: center;
}

.card h2 {
  font-size: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: left;
  font-weight: 300;
  box-shadow: 0px 0.1px 0px #ffffff;
  border-bottom-width: 10px;
  margin:0px;
}
.card h3{
  text-align: left !important;
  font-weight: 300;
  font-size: 15px;
  justify-self: left;
}

.card p {
  font-size: 13px;
  color: #a2a2a2;
  text-align: left;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
}

.nav-button {
  font-size: 2rem;
  background: none;
  border: none;
  cursor: pointer;
  
  margin: 0 1rem;
  position: absolute;
 
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}

.nav-button.left {
  left: 0%;
  
}

.nav-button.right {
  right: 76%;
}

.navarrow{
  width: 30px;
}

/*
.footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  position: relative;
}

.logo {
  width: 120px; /* Adjust size according to your image 
  margin-bottom: 10px;
}

.contact {
  margin: 20px 0;
  font-size: 1.2em;
  font-weight: 500;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  font-size: 1.5em;
  margin: 20px 0;
}

.icon {
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #1da1f2;
}

.privacy-terms {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  font-size: 0.9em;
}

.privacy-terms span {
  cursor: pointer;
  transition: color 0.3s ease;
}

.privacy-terms span:hover {
  color: #1da1f2;
}

.footer p {
  margin: 0;
  font-size: 0.8em;
  color: #888;
}

*/

@media only screen and (max-width: 480px) {
  .footerlogo{
    margin: 0px !important;
    padding: 0px !important;
    justify-self: center !important;
    width: 100% !important;
    height: 100% !important;
  }

  .contact{
    font-size: 10px !important;
    padding: 10px;
    
  }

  .footer-top{
    min-width: fit-content !important;
    padding: 0 !important;
    place-self: center;
    justify-content: space-between;
    gap: 20px;
  
  }

  .social-icons{
    height: 30px ;
  }

  .copyright{
    font-size: 10px !important;
  }
  .privacy-terms{
    font-size: 10px !important;
  }
}

.footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
}

.footerlogo {
  width: 60%;
  height:auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 100px;
}

.footer-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-bottom: 10px;
  padding-bottom: 30px;


}
.contact {
  margin: 20px 0;
  font-size: 40px;
  font-weight: bold;
  width: 80%;
}

.social-icons {
  display: flex;
  direction: rtl;
  gap: 10px;
  

}

.icon {
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #1da1f2;
}

.footer-bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #333;
  padding-top: 10px;
}

.copyright{
  font-size: 0.9em;
}

.privacy-terms {
  display: flex;
  
  gap: 15px;
  
}

.privacy-terms span {
  cursor: pointer;
  transition: color 0.3s ease;
}

.privacy-terms span:hover {
  color: #1da1f2;
}

.footer p {
  margin: 0;
  font-size: 0.8em;
  color: #888;
}




.Mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #242424;
}

.cards-container {
  place-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  max-width: 600px;
}

.crd {
  background-color: #242424;
  color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
}

.crd.reverse {
  flex-direction: row-reverse;
}

.crd-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crd-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.crd-content {
  place-content: center;
  flex: 1;
  text-align: left;
}


.crd-title {
  font-size: 10px;
  font-weight: 100;
  
  margin: 0;
}



.crd-subtitle{
  font-size: 20px !important;
  margin: 0px;
  font-weight: 100;
}

.crd-description {
  font-size: 10px;
  color: #9e9e9e;
}