
@media only screen and (max-width: 480px) {
    .footerlogo{
      margin: 0px !important;
      padding: 0px !important;
      justify-self: center !important;
      width: 100% !important;
      height: 100% !important;
    }
  
    .contact{
      font-size: 10px !important;
      padding: 10px;
      
    }

    
  
    .footer-top{
      min-width: fit-content !important;
      padding: 0 !important;
      place-self: center;
      justify-content: space-between;
      gap: 20px;
    
    }
  
    .social-icons{
      height: 30px ;
    }

    .icon{
      height: 30px;
    }
  
    .copyright{
      font-size: 10px !important;
    }
    .privacy-terms{
      font-size: 10px !important;
    }
  }
  
  .footer {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-x: hidden;
  }
  
  .footerlogo {
    width: 60%;
    height:auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-bottom: 100px;
  }
  
  .footer-top{
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-bottom: 10px;
    padding-bottom: 30px;
  
  
  }
  .contact {
    color:white;
    background-color: #000;
    border: none;

    text-align: left;
    margin: 20px 0;
    font-size: 40px;
    font-weight: bold;
    width: 100%;
  }
  
  .social-icons {
    display: flex;
    direction: rtl;
    gap: 10px;
    
  
  }
  
  .icon {
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .icon:hover {
    color: #1da1f2;
  }
  
  .footer-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid #333;
    padding-top: 10px;
  }
  
  .copyright{
    font-size: 0.9em;
  }
  
  .privacy-terms {
    display: flex;
    
    gap: 15px;
    
  }
  
  .privacy-terms span {
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .privacy-terms span:hover {
    color: #1da1f2;
  }
  
  .footer p {
    margin: 0;
    font-size: 0.8em;
    color: #888;
  }
  